import barba from '@barba/core';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import { A11y, Navigation, Scrollbar, Thumbs, EffectFade } from 'swiper/modules';

import { getA11y } from '../../utils/swiper';

export default function imagesModalSlider() {
	const pageLang = document.querySelector('html').getAttribute('lang') || 'en';
	const slidersGroup = document.querySelectorAll('.swiper--images-modal');

	if (!slidersGroup) return;

	// init
	// --------------------------------------------
	slidersGroup.forEach((elRoot) => {
		const sliderMain = elRoot.querySelector('.swiper--images-modal-main');
		const sliderThumb = elRoot.querySelector('.swiper--images-modal-thumb');

		const sliderSwiperThumb = new Swiper(sliderThumb, {
			a11y: getA11y(pageLang),
			loop: false,
			spaceBetween: 24,
			slidesPerView: 3,
			freeMode: false,
			watchSlidesProgress: true,
			modules: [A11y]
		});
		barba.hooks.beforeLeave(() => {
			sliderSwiperThumb.destroy(true, true);
		});

		const sliderSwiperMain = new Swiper(sliderMain, {
			a11y: getA11y(pageLang),
			loop: false,
			spaceBetween: 24,
			slidesPerView: 1,
			centeredSlides: false,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			navigation: {
				nextEl: '.swiper-button-next-custom',
				prevEl: '.swiper-button-prev-custom'
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				hide: false
			},
			thumbs: {
        swiper: sliderSwiperThumb,
      },
			modules: [Navigation, A11y, Scrollbar, Thumbs, EffectFade]
		});

		barba.hooks.beforeLeave(() => {
			sliderSwiperMain.destroy(true, true);
		});
	});
}
