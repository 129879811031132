export default () => ({
	activeTab: 1,
	maxHeight: 0,
	init() {
		setTimeout(() => {
			this.handleResize();
		}, 1);
	},
	handleResize() {
		const panels = this.$refs.containerPanels.querySelectorAll('[role="tabpanel"]');
		let maxHeight = 0;

		panels.forEach(panel => {
			const prevDisplay = panel.style.display;
			let height = 0;

			panel.style.display = 'block';
			height = panel.getBoundingClientRect().height;
			panel.style.display = prevDisplay;
			maxHeight = Math.max(maxHeight, height);
		});

		this.maxHeight = maxHeight;
	}
});
