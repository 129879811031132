export default () => ({
	lastScrollTop: 0,
	direction: null,
	init() {},
	handleScroll() {
		const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
		const scrollDirection = currentScroll > this.lastScrollTop ? 'down' : 'up';

		this.lastScrollTop = currentScroll;
		this.direction = scrollDirection;
	}
});

